.react-datepicker__week, .react-datepicker__day-names {
  flex-wrap: wrap;
  display: flex;
  width: 100% !important;
  justify-content: space-evenly;
}

.react-datepicker__day-name{
  color: #a0aec0!important;
  text-transform: uppercase;
  /* font-weight: 600!important; */
}
.react-datepicker__day{
  font-weight: 600;
  border-radius: 9999px!important;
  border: 2px solid white;
  width: 30px!important;
  height: 30px!important;
  color: #cbd5e0!important;
}


.react-datepicker__month,
.react-datepicker__month-container,
.react-datepicker {
  width: 100% !important;
}

.react-datepicker__day-names {
  width: 100% !important;
}

.react-datepicker__day--highlighted {
  border: 2px solid #2b6cb0;
  border-radius: 9999px!important;
  background: transparent!important;
  color: #2a4365!important;
}
.react-datepicker__day--highlighted:hover {
  background: gainsboro!important;
}



.react-datepicker__header{
  background: white!important;
}

.react-datepicker__day--selected{
  background: #2b6cb0!important;
  color: white!important;
}

.react-datepicker__day--selected:hover{
  background: #4e91d8!important;
  color: white!important;
}

.react-datepicker__day--today {
  position: relative;  /* Necesar pentru a poziționa pseudo-elementul */
  /* padding-bottom: 10px; Asigură spațiu pentru punctul albastru */
}

.react-datepicker__day--today::after {
  content: '';  /* Necesar pentru a crea pseudo-elementul */
  position: absolute;  /* Poziționare absolută față de div-ul părinte */
  bottom: -15%;  /* Alinează la baza div-ului */
  left: 50%;  /* Centrează punctul orizontal */
  transform: translateX(-50%);  /* Asigură centrarea exactă */
  width: 6px;  /* Lățimea punctului */
  height: 6px;  /* Înălțimea punctului */
  background-color: blue;  /* Culoarea punctului */
  border-radius: 50%;  /* Transformă div-ul în cerc */
}
